export default {
    defaultsoutes: [
        {
            id: 1,
            name: 'ProfileCounterparty',
            title: 'Компания',
            hash: ''
        },
        {
            id: 2,
            name: 'ProfileContracts',
            title: 'Договора',
            hash: ''
        },
        {
            id: 3,
            name: 'ProfileContacts',
            title: 'Контакты',
            hash: ''
        },
        {
            id: 4,
            name: 'ProfileNotifications',
            title: 'Уведомления',
            hash: ''
        },
    ]
}


