import Vue from 'vue'
import Vuex from 'vuex'
import {httpClient} from '../../shared/services';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        counterparty: null,
        allClsfCards: [],
        contacts: null,
        contact: null,
        contracts: null,
        contract: null,
        contractStatusHistorys: null,
        contractFiles: null,
        agents: null,
        notifications: null
    },
    mutations: {
        setCounterparty(state, data) {
            state.counterparty = data;
        },
        setAllClsfCards(state, data) {
            state.allClsfCards = data;
        },
        setContacts(state, data) {
            state.contacts = data;
        },
        setAgents(state, data) {
            state.agents = data;
        },
        setContact(state, data) {
            state.contact = data;
        },
        setContracts(state, data) {
            state.contracts = data;
        },
        setContract(state, data) {
            state.contract = data;
        }
        ,
        setContractStatusHistorys(state, data) {
            state.contractStatusHistorys = data;
        },
        setContractFiles(state, data) {
            state.contractFiles = data;
        },
        setNotifications(state, data) {
            state.notifications = data;
        },
    },
    actions: {
        getCounterparty({commit}, {tokenId}) {
            commit('setCounterparty', null);
            return httpClient
                .get(`v2/Company?tokenId=${tokenId}`)
                .then((response) => {
                    commit('setCounterparty', response.data);
                }).catch(function (error) {
                    console.error(error);
                });
        },
        getAllClsfCards({commit}) {
            commit('setAllClsfCards', null);
            return httpClient
                .get(`v2/Catalog/clsfKinds`)
                .then((response) => {
                    commit('setAllClsfCards', response.data);
                }).catch(function (error) {
                    console.error(error);
                });
        },
        updateCounterparty({commit}, {
            counterpartyId,
            CompanyName,
            CompanyBin,
            isResident,
            certificatNDS,
            Email,
            Mobile,
            Phone,
            legalZip,
            legalLocalityId,
            legalDetailAddress,
            actualZip,
            actualLocalityId,
            actualDetailAddress,
            tokenId,
            isAddressEquals
        }) {
            return httpClient
                .post(`v2/Company?tokenId=${tokenId}`, {
                    counterpartyId: counterpartyId,
                    companyName: CompanyName,
                    bin: CompanyBin,
                    isResident: isResident,
                    certificatNDS: certificatNDS,
                    default_eMail: Email,
                    defaultMobile: Mobile,
                    defaultPhone: Phone,
                    legalAddress: legalLocalityId != undefined ? {
                        localityId: legalLocalityId,
                        zip: legalZip,
                        detailAddress: legalDetailAddress
                    } : null,
                    address: actualLocalityId != undefined ? {
                        localityId: actualLocalityId,
                        zip: actualZip,
                        detailAddress: actualDetailAddress
                    } : null,
                    isAddressEquals: isAddressEquals
                })
                .then((response) => {
                    commit('setCounterparty', response.data);
                }).catch(function (error) {
                    console.error(error);
                });
        },
        getContacts({commit}, {tokenId}) {
            commit('setContacts', null);
            return httpClient
                .get(`v2/Contacts?tokenId=${tokenId}`)
                .then((response) => {
                    commit('setContacts', response.data);
                }).catch(function (error) {
                    console.error(error);
                });
        },
        updateContact({commit}, {
            contactId,
            contactName,
            position,
            responsibility,
            mobile,
            phone,
            email,
            tokenId
        }) {
            return httpClient
                .post(`v2/Contacts?tokenId=${tokenId}`, {
                    contactId,
                    contactName,
                    position,
                    responsibility,
                    mobile,
                    phone,
                    email
                })
                .then((response) => {
                    commit('setContact', response.data);
                });
        },
        addContact({commit}, {
            contactName,
            position,
            responsibility,
            mobile,
            phone,
            email,
            tokenId
        }) {
            return httpClient
                .post(`v2/Contacts?tokenId=${tokenId}`, {
                    contactName,
                    position,
                    responsibility,
                    mobile,
                    phone,
                    email
                })
                .then((response) => {
                    commit('setContact', response.data);
                });
        },
        deleteContact({commit}, {tokenId, contactId}) {
            return httpClient
                .delete(`v2/Contacts/${contactId}?tokenId=${tokenId}`).then(() => {
                    commit('setContact', null);
                });
        },
        getAgents({commit}, {tokenId}) {
            commit('setAgents', null);
            return httpClient
                .get(`v2/Agent?tokenId=${tokenId}`)
                .then((response) => {
                    commit('setAgents', response.data);
                }).catch(function (error) {
                    console.error(error);
                });
        },
        getContracts({commit}, {tokenId}) {
            commit('setContracts', null);
            return httpClient
                .get(`v2/Contracts?tokenId=${tokenId}`)
                .then((response) => {
                    commit('setContracts', response.data);
                }).catch(function (error) {
                    console.error(error);
                });
        },
        getContract({commit}, {tokenId, contractId}) {
            commit('setContract', null);
            return httpClient
                .get(`v2/Contracts/${contractId}?tokenId=${tokenId}`)
                .then((response) => {
                    commit('setContract', response.data);
                });
        },
        getContractStatusHistorys({commit}, {tokenId, contractId}) {
            commit('setContractStatusHistorys', null);
            return httpClient
                .get(`v2/Contracts/${contractId}/StatusHistorys?tokenId=${tokenId}`)
                .then((response) => {
                    commit('setContractStatusHistorys', response.data);
                }).catch(function (error) {
                    console.error(error);
                });
        },
        getContractFiles({commit}, {tokenId, contractId}) {
            commit('setContractFiles', null);
            return httpClient
                .get(`v2/Contracts/${contractId}/Files?tokenId=${tokenId}`)
                .then((response) => {
                    commit('setContractFiles', response.data);
                }).catch(function (error) {
                    console.error(error);
                });
        },
        getNotifications({commit}, {tokenId}) {
            commit('setNotifications', null);
            return httpClient
                .get(`v2/Notifications?tokenId=${tokenId}`)
                .then((response) => {
                    commit('setNotifications', response.data.items);
                }).catch(function (error) {
                    console.error(error);
                });
        },
    },
    getters: {},
    modules: {}
});
