<template>
    <div>
        <vue-title title="Компания | KEL.KZ"></vue-title>
        <Header :routes="headerRoutes.defaultsoutes" v-if="!reRenderComponent"/>
        <div class="container py-4">
            <div class="long-title text-center mb-3">
                <h1 class="page-title">Компания</h1>
            </div>
            <div class="row">
                <div class="col-12">
                    <div id="form-container">
                        <div v-if="okMessage" class="alert alert-success mb-3" role="alert">
                            {{ okMessage }}
                        </div>
                        <div v-if="errorMessage" class="alert alert-danger mb-3" role="alert">
                            <strong>Внимание!</strong> {{ errorMessage }}
                        </div>
                        <form class="counterparty-form" @submit.prevent="onSubmit">
                            <DxForm
                                    id="form"
                                    :col-count="1"
                                    :form-data="formData"
                                    :read-only="!Agent.isAdmin"
                                    :show-colon-after-label="true"
                                    :show-validation-summary="true"
                                    :label-location="labelLocation"
                                    :disabled="isLoading"
                            >
                                <DxSimpleItem
                                        data-field="CompanyName"
                                >
                                    <DxLabel text="Наименование компании"/>
                                    <DxRequiredRule message="Укажите наименование компании"/>
                                </DxSimpleItem>
                                <DxColCountByScreen :xs="1" :sm="1"/>
                                <DxGroupItem
                                        :colCount="2"
                                >
                                    <DxColCountByScreen :xs="1" :sm="1"/>
                                    <DxGroupItem
                                            :colCount="4"
                                    >
                                        <DxColCountByScreen :xs="1" :sm="1"/>
                                        <DxSimpleItem data-field="CompanyBin"
                                                      :colSpan="3"
                                                      :editor-options="{onValueChanged: handleCompanyBinValueChange}"
                                        >
                                            <DxLabel text="БИН компании"/>
                                            <DxRequiredRule message="Укажите БИН компании"/>
                                        </DxSimpleItem>
                                        <DxSimpleItem
                                                data-field="isResident"
                                                :editor-options="{
                                                    displayExpr: 'name',
                                                    valueExpr: 'value',
                                                    value: formData.isResident,
                                                    items: [
                                                        {
                                                            name: 'Да',
                                                            value: true
                                                        },
                                                        {
                                                            name: 'Нет',
                                                            value: false
                                                        }
                                                    ]
                                                }"
                                                editor-type="dxSelectBox"
                                        >
                                            <DxLabel text="Резидент"/>
                                        </DxSimpleItem>
                                    </DxGroupItem>
                                    <DxSimpleItem
                                            data-field="certificatNDS"
                                            :editor-options="{placeholder: 'Серия 00000 №0000000 От 01.01.2021'}"
                                            cssClass="mb-3"
                                    >
                                        <DxLabel text="Свидетельство о постановке на учет по НДС"/>
                                    </DxSimpleItem>
                                </DxGroupItem>
                                <DxGroupItem
                                        :colCount="3"
                                >
                                    <DxColCountByScreen :xs="1" :sm="1"/>
                                    <DxSimpleItem data-field="Email">
                                        <DxLabel text="Email"/>
                                        <DxEmailRule message="Неверно указан Email"/>
                                        <DxRequiredRule message="Укажите Email"/>
                                    </DxSimpleItem>
                                    <DxSimpleItem data-field="Mobile"
                                                  :editor-options="mobileEditorOptions"
                                    >
                                        <DxLabel text="Моб. телефон"/>
<!--                                        <DxPatternRule-->
<!--                                                :pattern="phonePattern"-->
<!--                                                message="Телефон не соответствует формату"-->
<!--                                        />-->
                                    </DxSimpleItem>
                                    <DxSimpleItem data-field="Phone"
                                                  :editor-options="phoneEditorOptions"
                                                  cssClass="mb-3"
                                    >
                                        <DxLabel text="Раб. телефон"/>
<!--                                        <DxPatternRule-->
<!--                                                :pattern="phonePattern"-->
<!--                                                message="Телефон не соответствует формату"-->
<!--                                        />-->
                                    </DxSimpleItem>
                                </DxGroupItem>
                                <DxGroupItem
                                        :colCount="2"
                                >
                                    <DxColCountByScreen :xs="1" :sm="1"/>
                                    <DxGroupItem
                                            caption="Юридический адрес"
                                            :colCount="4"
                                    >
                                        <DxColCountByScreen :xs="1" :sm="1"/>
                                        <DxSimpleItem
                                                data-field="legalLocalityId"
                                                :editor-options="{
                                                    dataSource: storeLegalLocality,
                                                    displayExpr: 'displayName',
                                                    valueExpr: 'localityId',
                                                    value: formData.legalLocalityId,
                                                    searchEnabled: true,
                                                    showDataBeforeSearch: true,
                                                    showClearButton: true,
                                                    onValueChanged: handleLegalLocalityValueChange
                                                }"
                                                editor-type="dxSelectBox"
                                                :colSpan="3"
                                        >
                                            <DxLabel text="Населенный пункт"/>
                                        </DxSimpleItem>
                                        <DxSimpleItem
                                                data-field="legalZip"
                                                :editor-options="{onValueChanged: handleLegalZipValueChange}"
                                        >
                                            <DxLabel text="Индекс"/>
                                        </DxSimpleItem>
                                        <DxSimpleItem
                                                data-field="legalDetailAddress"
                                                :colSpan="4"
                                                :editor-options="{onValueChanged: handleLegalDetailAddressValueChange}"
                                        >
                                            <DxLabel text="Адрес"/>
                                        </DxSimpleItem>
                                        <DxSimpleItem
                                                :editor-options="checkBoxIsAddressEqualsOptions"
                                                data-field="isAddressEquals"
                                                editor-type="dxCheckBox"
                                                :colSpan="4"
                                        >
                                            <DxLabel :visible="false"/>
                                        </DxSimpleItem>
                                    </DxGroupItem>
                                    <DxGroupItem
                                            caption="Фактический адрес"
                                            :colCount="4"
                                    >
                                        <DxColCountByScreen :xs="1" :sm="1"/>
                                        <DxSimpleItem
                                                data-field="actualLocalityId"
                                                :editor-options="{
                                                    dataSource: storeActualLocality,
                                                    displayExpr: 'displayName',
                                                    valueExpr: 'localityId',
                                                    value: formData.actualLocalityId,
                                                    searchEnabled: true,
                                                    showDataBeforeSearch: true,
                                                    showClearButton: true,
                                                    autocomplete:  'new-user',
                                                    disabled: isAddressEquals
                                                }"
                                                editor-type="dxSelectBox"
                                                :colSpan="3"
                                        >
                                            <DxLabel text="Населенный пункт"/>
                                        </DxSimpleItem>
                                        <DxSimpleItem
                                                data-field="actualZip"
                                                :editor-options="{disabled: isAddressEquals, value: formData.actualZip}"
                                        >
                                            <DxLabel text="Индекс"/>
                                        </DxSimpleItem>
                                        <DxSimpleItem
                                                data-field="actualDetailAddress"
                                                :colSpan="4"
                                                :editor-options="{disabled: isAddressEquals, value: formData.actualDetailAddress}"
                                        >
                                            <DxLabel text="Адрес"/>
                                        </DxSimpleItem>
                                    </DxGroupItem>
                                </DxGroupItem>
                                <DxGroupItem
                                        caption="Деятельность компании"
                                        :colCount="4"
                                >
                                    <DxColCountByScreen :xs="1" :sm="2"/>
                                    <DxSimpleItem
                                            v-for="clsfCard in allClsfCards" :key="clsfCard.clsfKind"
                                            :data-field="clsfCard.clsfKind"
                                            editor-type="dxCheckBox"
                                            :cssClass="'activity-company-dx-check-box'"
                                            :editor-options="{
                                                    value: clsfCard.isActive,
                                                    text: clsfCard.clsfCaption,
                                                    disabled: clsfCardIsDisabled(clsfCard.clsfKind)
                                                }"
                                    >
                                        <DxLabel :visible="false"/>
                                    </DxSimpleItem>
                                </DxGroupItem>
                                <DxGroupItem
                                        v-if="Agent.isAdmin"
                                        :colCount="1"
                                >

                                    <DxButtonItem
                                            :button-options="buttonOptions"
                                    />
                                </DxGroupItem>
                            </DxForm>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
    import headerRoutes from './header-routes';
    import {mapGetters} from 'vuex';
    import localStore from './store';
    import {httpClient} from '../../shared/services';
    import {Header, Footer} from '../../shared/components';
    import {
        DxForm,
        DxSimpleItem,
        DxLabel,
        DxButtonItem,
        DxRequiredRule,
        DxEmailRule,
        //DxPatternRule,
        DxColCountByScreen,
        DxGroupItem,
    } from 'devextreme-vue/form';
    import CustomStore from 'devextreme/data/custom_store';

    export default {
        name: 'ProfileCounterparty',
        components: {
            Header,
            Footer,
            DxForm,
            DxSimpleItem,
            DxLabel,
            DxButtonItem,
            DxRequiredRule,
            DxEmailRule,
            //DxPatternRule,
            DxColCountByScreen,
            DxGroupItem
        },
        data() {
            return {
                headerRoutes,
                reRenderComponent: false,
                counterparty: null,
                allClsfCards: [],
                formData: {},
                labelLocation: 'top',
                okMessage: "",
                errorMessage: "",
                buttonOptions: {
                    text: 'Сохранить',
                    type: 'default',
                    useSubmitBehavior: true
                },
                mobileEditorOptions: {
                    mask: '+X (000) 000 00 00',
                    maskRules: {
                        X: /[1]|[2]|[3]|[4]|[5]|[6]|[7]|[9]|[0]/
                    },
                    //useMaskedValue: true,
                    maskInvalidMessage: 'Номер не соответствует формату мобильного телефона.',
                    autocomplete:  'new-user'
                },
                phoneEditorOptions: {
                    mask: '+X (000) 000 00 00',
                    maskRules: {
                        X: /[1]|[2]|[3]|[4]|[5]|[6]|[7]|[9]|[0]/
                    },
                    //useMaskedValue: true,
                    maskInvalidMessage: 'Номер не соответствует формату телефона.',
                    autocomplete:  'new-user'
                },
                phonePattern: /^((1|2|3|4|5|6|7|9|0)\d{10})$/,
                checkBoxIsAddressEqualsOptions: {
                    text: 'Фактический адрес совпадает с Юридическим',
                    value: this.isAddressEquals,
                    onValueChanged: this.handleIsAddressEqualsValueChange
                },
                isAddressEquals: false,
                storeLegalLocality: null,
                storeActualLocality: null
            }
        },
        mounted() {
            this.getCounterparty();
            this.getAllClsfCards();
        },
        methods: {
            getCounterparty() {
                this.$store.dispatch('loading', true).then(() => {
                    localStore.dispatch('getCounterparty', {tokenId: this.Agent.tokenId}).then(() => {
                        this.counterparty = localStore.state.counterparty;

                        let Phone = this.$options.filters.changeStartEightInPhone(this.counterparty.defaultPhone);
                        let Mobile = this.$options.filters.changeStartEightInPhone(this.counterparty.defaultMobile);

                        this.formData = {
                            CompanyName: this.counterparty.companyName,
                            CompanyBin: this.counterparty.bin,
                            isResident: this.counterparty.isResident,
                            certificatNDS: this.counterparty.certificatNDS,
                            Email: this.counterparty.default_eMail,
                            Mobile: Mobile,
                            Phone: Phone,
                            legalZip: this.counterparty.legalAddress?.zip,
                            legalLocalityId: this.counterparty.legalAddress?.localityId,
                            legalDetailAddress: this.counterparty.legalAddress?.detailAddress,
                            actualZip: this.counterparty.address?.zip,
                            actualLocalityId: this.counterparty.address?.localityId,
                            actualDetailAddress: this.counterparty.address?.detailAddress,
                            isAddressEquals: this.counterparty.isAddressEquals
                        };

                        this.isAddressEquals = this.counterparty.isAddressEquals;

                        this.storeLegalLocality = new CustomStore({
                            key: 'localityId',
                            byKey: async function (key) {
                                if(key) {
                                    if(window.legalLocality) {
                                        if(window.legalLocality.localityId != key) {
                                            return httpClient.get(`v2/Catalog/Localitys/${key}`)
                                                .then((locality) => {
                                                    window.legalLocality = locality.data;
                                                    return locality.data;
                                                })
                                                .catch(() => {
                                                    throw 'Data Loading Error';
                                                });
                                        } else {
                                            return window.legalLocality;
                                        }
                                    } else {
                                        return httpClient.get(`v2/Catalog/Localitys/${key}`)
                                            .then((locality) => {
                                                window.legalLocality = locality.data;
                                                return locality.data;
                                            })
                                            .catch(() => {
                                                throw 'Data Loading Error';
                                            });
                                    }
                                }
                            },
                            load: (loadOptions) => {
                                //console.log(loadOptions);

                                let searchValue = loadOptions.searchValue != null ? loadOptions.searchValue : '';

                                return httpClient.get(`v2/Catalog/Localitys?filter={"localityName":{"op":"con","value":"${searchValue}"}}`)
                                    .then((data) => {
                                        return {
                                            key: 'localityId',
                                            data: data.data.items,
                                            totalCount: data.totals,
                                            summary: data.totals,
                                            groupCount: data.count,
                                            userData: data.data,
                                        };
                                    })
                                    .catch(() => {
                                        throw 'Data Loading Error';
                                    });
                            }
                        });

                        this.storeActualLocality = new CustomStore({
                            key: 'localityId',
                            byKey: function (key) {
                                if(key) {
                                    if(window.actualLocality) {
                                        if(window.actualLocality.localityId != key) {
                                            return httpClient.get(`v2/Catalog/Localitys/${key}`)
                                                .then((locality) => {
                                                    window.actualLocality = locality.data;
                                                    return locality.data;
                                                })
                                                .catch(() => {
                                                    throw 'Data Loading Error';
                                                });
                                        } else {
                                            return window.actualLocality;
                                        }
                                    } else {
                                        return httpClient.get(`v2/Catalog/Localitys/${key}`)
                                            .then((locality) => {
                                                window.actualLocality = locality.data;
                                                return locality.data;
                                            })
                                            .catch(() => {
                                                throw 'Data Loading Error';
                                            });
                                    }
                                }
                            },
                            load: (loadOptions) => {
                                //console.log(loadOptions);

                                let searchValue = loadOptions.searchValue != null ? loadOptions.searchValue : '';

                                return httpClient.get(`v2/Catalog/Localitys?filter={"localityName":{"op":"con","value":"${searchValue}"}}`)
                                    .then((data) => {
                                        return {
                                            key: 'localityId',
                                            data: data.data.items,
                                            totalCount: data.totals,
                                            summary: data.totals,
                                            groupCount: data.count,
                                            userData: data.data,
                                        };
                                    })
                                    .catch(() => {
                                        throw 'Data Loading Error';
                                    });
                            }
                        });

                        //this.$store.dispatch('loading', false);
                    });
                });
            },
            onSubmit: async function () {
                this.okMessage = "";
                this.errorMessage = "";

                for (let card of this.allClsfCards.entries() || []) {
                    let objIndex = this.clsfCards.findIndex((obj => obj.clsfKind == card[1].clsfKind));
                    let isActive = this.formData[card[1].clsfKind];

                    if(objIndex == -1) {
                        if(isActive) {
                            // Создаем карточку
                            httpClient
                                .post(`v2/ClsfCards?cardKind=${card[1].clsfKind}&tokenId=${this.Agent.tokenId}`)
                                .then((response) => {
                                    this.clsfCards.push(response.data);
                                    this.$store.commit('setClsfCards', this.clsfCards);
                                    this.getAllClsfCards();

                                    this.reRenderComponent = true;
                                    setTimeout(() => {
                                        this.reRenderComponent = false;
                                    }, 100);
                                }).catch(this.handleErrorClsfCards);
                        }
                    } else {
                        let clsfCard = this.clsfCards[objIndex];

                        if(clsfCard.isActive != isActive) {
                            // Отключаем и включаем карточку
                            httpClient
                                .post(`v2/ClsfCards/${clsfCard.clsfCardId}/${isActive ? 'Enable' : 'Disable'}/?tokenId=${this.Agent.tokenId}`)
                                .then((response) => {
                                    let objIndex = this.clsfCards.findIndex((obj => obj.clsfKind == response.data.clsfKind));
                                    this.clsfCards[objIndex] = response.data;
                                    this.$store.commit('setClsfCards', this.clsfCards);
                                    this.getAllClsfCards();

                                    this.reRenderComponent = true;
                                    setTimeout(() => {
                                        this.reRenderComponent = false;
                                    }, 50);
                                }).catch(this.handleErrorClsfCards);
                        }
                    }
                }

                this.$store.dispatch('loading', true).then(() => {
                    let counterpartyId = this.Agent.companyId;
                    let tokenId = this.Agent.tokenId;

                    let {
                        CompanyName,
                        CompanyBin,
                        isResident,
                        certificatNDS,
                        Email,
                        Mobile,
                        Phone,
                        legalZip,
                        legalLocalityId,
                        legalDetailAddress,
                        actualZip,
                        actualLocalityId,
                        actualDetailAddress,
                        isAddressEquals
                    } = this.formData;

                    if(Mobile) {
                        Mobile = this.$options.filters.clearPhone(Mobile);
                    }

                    if(Phone) {
                        Phone = this.$options.filters.clearPhone(Phone);
                    }

                    localStore.dispatch('updateCounterparty', {
                        counterpartyId,
                        CompanyName,
                        CompanyBin,
                        isResident,
                        certificatNDS,
                        Email,
                        Mobile,
                        Phone,
                        legalZip,
                        legalLocalityId,
                        legalDetailAddress,
                        actualZip,
                        actualLocalityId,
                        actualDetailAddress,
                        tokenId,
                        isAddressEquals
                    }).then(() => {
                        this.okMessage = "Данные о компании успешно сохранены.";
                        this.counterparty = localStore.state.counterparty;

                      let Phone = this.$options.filters.changeStartEightInPhone(this.counterparty.defaultPhone);
                      let Mobile = this.$options.filters.changeStartEightInPhone(this.counterparty.defaultMobile);

                        this.formData = {
                            CompanyName: this.counterparty.companyName,
                            CompanyBin: this.counterparty.bin,
                            isResident: this.counterparty.isResident,
                            certificatNDS: this.counterparty.certificatNDS,
                            Email: this.counterparty.default_eMail,
                            Mobile: Mobile,
                            Phone: Phone,
                            legalZip: this.counterparty.legalAddress?.zip,
                            legalLocalityId: this.counterparty.legalAddress?.localityId,
                            legalDetailAddress: this.counterparty.legalAddress?.detailAddress,
                            actualZip: this.counterparty.address?.zip,
                            actualLocalityId: this.counterparty.address?.localityId,
                            actualDetailAddress: this.counterparty.address?.detailAddress,
                            isAddressEquals: this.counterparty.isAddressEquals
                        };

                        //this.$store.dispatch('loading', false);
                        //this.getAllClsfCards();

                    }).catch(this.handleError);
                });
            },
            handleError: function (error) {
                this.errorMessage = "Ошибка при сохранении данных о компании. Проверьте указанные вами данные.";
                this.$store.dispatch('loading', false);
                console.error(error);
            },
            handleErrorClsfCards: function (error) {
                this.errorMessage = "Ошибка при сохранении деятельности компании.";
                this.$store.dispatch('loading', false);
                console.error(error);
            },
            handleIsAddressEqualsValueChange(e) {
                this.isAddressEquals = e.value;

                if(this.isAddressEquals) {
                    this.formData.actualZip = this.formData.legalZip;
                    if(this.formData.actualLocalityId != this.formData.legalLocalityId) {
                        this.formData.actualLocalityId = this.formData.legalLocalityId;
                    }
                    this.formData.actualDetailAddress = this.formData.legalDetailAddress;
                }
            },
            isValidBIN(bin) {
                if(bin.length == 12) {
                    //Проверяем контрольный разряд
                    let b1 = Array(1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11);
                    let b2 = Array(3, 4, 5, 6, 7, 8, 9, 10, 11, 1, 2);
                    let a = Array();
                    let controll = 0;

                    for (let i = 0; i < 12; i++) {
                        a[i] = bin.substr(i, 1);
                        if (i < 11) controll += a[i] * b1[i];
                    }

                    controll = controll % 11;

                    if (controll == 10) {
                        controll = 0;
                        for (let i = 0; i < 11; i++)
                            controll += a[i] * b2[i];
                        controll = controll % 11;
                    }

                    if (controll == a[11]) {
                        return true;
                    }
                }

                return false;
            },
            handleCompanyBinValueChange(e) {
                if(e.previousValue != "" && e.value != "") {
                    this.formData.isResident = this.isValidBIN(e.value);
                }
            },
            handleLegalLocalityValueChange(e) {
                if(this.storeLegalLocality && this.isAddressEquals) {
                    if(this.formData.actualLocalityId != e.value) {
                        this.storeLegalLocality.byKey(e.value).then((dataItem) => {
                            if(dataItem) {
                                this.formData.actualLocalityId = dataItem.localityId;
                            }
                        });
                    }
                }
            },
            handleLegalZipValueChange() {
                if(this.isAddressEquals) {
                    this.formData.actualZip = this.formData.legalZip;
                }
            },
            handleLegalDetailAddressValueChange() {
                if(this.isAddressEquals) {
                    this.formData.actualDetailAddress = this.formData.legalDetailAddress;
                }
            },
            getAllClsfCards() {
                localStore.dispatch('getAllClsfCards').then(() => {
                    for (let card of localStore.state.allClsfCards.entries() || []) {

                        let objIndex = this.allClsfCards ? this.allClsfCards.findIndex((obj => obj.clsfKind == card[1].clsfKind)) : -1;
                        if(objIndex == -1) {
                            this.allClsfCards.push({ ...card[1], isActive: false, clsfCardId: "", clsfCaption: card[1].clsfCaption });
                        } else {
                            this.allClsfCards[objIndex] = { ...card[1], isActive: false, clsfCaption: card[1].clsfCaption };
                        }
                    }

                    for (let card of this.clsfCards.entries() || []) {
                        let objIndex = this.allClsfCards.findIndex((obj => obj.clsfKind == card[1].clsfKind));
                        if(objIndex != -1) {
                            this.allClsfCards[objIndex].isActive = card[1].isActive;
                        }
                    }

                    this.$store.dispatch('loading', false);
                });
            },
            clsfCardIsDisabled(clsfKind) {

                switch (clsfKind) {
                    case 'ПР':
                        return false;
                }

                return true;
            }
        },
        computed: {
            ...mapGetters([
                'isLoading',
                'Agent',
                'clsfCards'
            ])
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
