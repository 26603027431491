var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-title',{attrs:{"title":"Компания | KEL.KZ"}}),(!_vm.reRenderComponent)?_c('Header',{attrs:{"routes":_vm.headerRoutes.defaultsoutes}}):_vm._e(),_c('div',{staticClass:"container py-4"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{attrs:{"id":"form-container"}},[(_vm.okMessage)?_c('div',{staticClass:"alert alert-success mb-3",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.okMessage)+" ")]):_vm._e(),(_vm.errorMessage)?_c('div',{staticClass:"alert alert-danger mb-3",attrs:{"role":"alert"}},[_c('strong',[_vm._v("Внимание!")]),_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e(),_c('form',{staticClass:"counterparty-form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('DxForm',{attrs:{"id":"form","col-count":1,"form-data":_vm.formData,"read-only":!_vm.Agent.isAdmin,"show-colon-after-label":true,"show-validation-summary":true,"label-location":_vm.labelLocation,"disabled":_vm.isLoading}},[_c('DxSimpleItem',{attrs:{"data-field":"CompanyName"}},[_c('DxLabel',{attrs:{"text":"Наименование компании"}}),_c('DxRequiredRule',{attrs:{"message":"Укажите наименование компании"}})],1),_c('DxColCountByScreen',{attrs:{"xs":1,"sm":1}}),_c('DxGroupItem',{attrs:{"colCount":2}},[_c('DxColCountByScreen',{attrs:{"xs":1,"sm":1}}),_c('DxGroupItem',{attrs:{"colCount":4}},[_c('DxColCountByScreen',{attrs:{"xs":1,"sm":1}}),_c('DxSimpleItem',{attrs:{"data-field":"CompanyBin","colSpan":3,"editor-options":{onValueChanged: _vm.handleCompanyBinValueChange}}},[_c('DxLabel',{attrs:{"text":"БИН компании"}}),_c('DxRequiredRule',{attrs:{"message":"Укажите БИН компании"}})],1),_c('DxSimpleItem',{attrs:{"data-field":"isResident","editor-options":{
                                                    displayExpr: 'name',
                                                    valueExpr: 'value',
                                                    value: _vm.formData.isResident,
                                                    items: [
                                                        {
                                                            name: 'Да',
                                                            value: true
                                                        },
                                                        {
                                                            name: 'Нет',
                                                            value: false
                                                        }
                                                    ]
                                                },"editor-type":"dxSelectBox"}},[_c('DxLabel',{attrs:{"text":"Резидент"}})],1)],1),_c('DxSimpleItem',{attrs:{"data-field":"certificatNDS","editor-options":{placeholder: 'Серия 00000 №0000000 От 01.01.2021'},"cssClass":"mb-3"}},[_c('DxLabel',{attrs:{"text":"Свидетельство о постановке на учет по НДС"}})],1)],1),_c('DxGroupItem',{attrs:{"colCount":3}},[_c('DxColCountByScreen',{attrs:{"xs":1,"sm":1}}),_c('DxSimpleItem',{attrs:{"data-field":"Email"}},[_c('DxLabel',{attrs:{"text":"Email"}}),_c('DxEmailRule',{attrs:{"message":"Неверно указан Email"}}),_c('DxRequiredRule',{attrs:{"message":"Укажите Email"}})],1),_c('DxSimpleItem',{attrs:{"data-field":"Mobile","editor-options":_vm.mobileEditorOptions}},[_c('DxLabel',{attrs:{"text":"Моб. телефон"}})],1),_c('DxSimpleItem',{attrs:{"data-field":"Phone","editor-options":_vm.phoneEditorOptions,"cssClass":"mb-3"}},[_c('DxLabel',{attrs:{"text":"Раб. телефон"}})],1)],1),_c('DxGroupItem',{attrs:{"colCount":2}},[_c('DxColCountByScreen',{attrs:{"xs":1,"sm":1}}),_c('DxGroupItem',{attrs:{"caption":"Юридический адрес","colCount":4}},[_c('DxColCountByScreen',{attrs:{"xs":1,"sm":1}}),_c('DxSimpleItem',{attrs:{"data-field":"legalLocalityId","editor-options":{
                                                    dataSource: _vm.storeLegalLocality,
                                                    displayExpr: 'displayName',
                                                    valueExpr: 'localityId',
                                                    value: _vm.formData.legalLocalityId,
                                                    searchEnabled: true,
                                                    showDataBeforeSearch: true,
                                                    showClearButton: true,
                                                    onValueChanged: _vm.handleLegalLocalityValueChange
                                                },"editor-type":"dxSelectBox","colSpan":3}},[_c('DxLabel',{attrs:{"text":"Населенный пункт"}})],1),_c('DxSimpleItem',{attrs:{"data-field":"legalZip","editor-options":{onValueChanged: _vm.handleLegalZipValueChange}}},[_c('DxLabel',{attrs:{"text":"Индекс"}})],1),_c('DxSimpleItem',{attrs:{"data-field":"legalDetailAddress","colSpan":4,"editor-options":{onValueChanged: _vm.handleLegalDetailAddressValueChange}}},[_c('DxLabel',{attrs:{"text":"Адрес"}})],1),_c('DxSimpleItem',{attrs:{"editor-options":_vm.checkBoxIsAddressEqualsOptions,"data-field":"isAddressEquals","editor-type":"dxCheckBox","colSpan":4}},[_c('DxLabel',{attrs:{"visible":false}})],1)],1),_c('DxGroupItem',{attrs:{"caption":"Фактический адрес","colCount":4}},[_c('DxColCountByScreen',{attrs:{"xs":1,"sm":1}}),_c('DxSimpleItem',{attrs:{"data-field":"actualLocalityId","editor-options":{
                                                    dataSource: _vm.storeActualLocality,
                                                    displayExpr: 'displayName',
                                                    valueExpr: 'localityId',
                                                    value: _vm.formData.actualLocalityId,
                                                    searchEnabled: true,
                                                    showDataBeforeSearch: true,
                                                    showClearButton: true,
                                                    autocomplete:  'new-user',
                                                    disabled: _vm.isAddressEquals
                                                },"editor-type":"dxSelectBox","colSpan":3}},[_c('DxLabel',{attrs:{"text":"Населенный пункт"}})],1),_c('DxSimpleItem',{attrs:{"data-field":"actualZip","editor-options":{disabled: _vm.isAddressEquals, value: _vm.formData.actualZip}}},[_c('DxLabel',{attrs:{"text":"Индекс"}})],1),_c('DxSimpleItem',{attrs:{"data-field":"actualDetailAddress","colSpan":4,"editor-options":{disabled: _vm.isAddressEquals, value: _vm.formData.actualDetailAddress}}},[_c('DxLabel',{attrs:{"text":"Адрес"}})],1)],1)],1),_c('DxGroupItem',{attrs:{"caption":"Деятельность компании","colCount":4}},[_c('DxColCountByScreen',{attrs:{"xs":1,"sm":2}}),_vm._l((_vm.allClsfCards),function(clsfCard){return _c('DxSimpleItem',{key:clsfCard.clsfKind,attrs:{"data-field":clsfCard.clsfKind,"editor-type":"dxCheckBox","cssClass":'activity-company-dx-check-box',"editor-options":{
                                                    value: clsfCard.isActive,
                                                    text: clsfCard.clsfCaption,
                                                    disabled: _vm.clsfCardIsDisabled(clsfCard.clsfKind)
                                                }}},[_c('DxLabel',{attrs:{"visible":false}})],1)})],2),(_vm.Agent.isAdmin)?_c('DxGroupItem',{attrs:{"colCount":1}},[_c('DxButtonItem',{attrs:{"button-options":_vm.buttonOptions}})],1):_vm._e()],1)],1)])])])]),_c('Footer')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"long-title text-center mb-3"},[_c('h1',{staticClass:"page-title"},[_vm._v("Компания")])])}]

export { render, staticRenderFns }